import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  CssBaseline,
  Grid,
  Typography,
} from "@material-ui/core";
import Select2 from 'react-select';
import CloseIcon from "@material-ui/icons/Close";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Button } from "../../../../components/Wrappers/Wrappers";

export default function UpdateLandlordDialog(props) {
  let {
    classes,
    rowDataLandLord,
    assignLandlordModal,
    updateLandlordClose,
    getLandlordListItems,
    landlordAssignSubmit,
    handleAssignLandlordDropDown
  } = props;


  console.log("rowDataLandLord______101010101.........................__________", rowDataLandLord);

  let colourOptions = []
  if (getLandlordListItems && getLandlordListItems.length > 0) {
    getLandlordListItems.forEach((emp) => {
      colourOptions.push(
        {
          value: emp.company_name,
          label: emp.company_name,
          id: emp.id,
        }
      )
    })
  }


  return (
    <Dialog fullScreen open={assignLandlordModal} onClose={updateLandlordClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={updateLandlordClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Assign Landlord
          </Typography>
          <Button color="inherit" onClick={updateLandlordClose}>
            Cancel
          </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Assign Landlord
          </Typography>



          <ValidatorForm onSubmit={landlordAssignSubmit}>
            <Grid container spacing={2} style={{ width: '500px' }}>


              {/* {JSON.stringify(rowDataLandLord)} */}

              <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                <label htmlFor="resourceList" style={{ color: 'grey' }}>Landlord List</label>

                <Select2
                  id="landlordList"
                  isMulti
                  width="full"
                  name="landlordList"
                  onChange={handleAssignLandlordDropDown}
                  options={colourOptions}
                  defaultValue={rowDataLandLord}
                  className="basic-multi-select width: 'full'"
                  classNamePrefix="Activities"
                // value={selectedColourOptions && selectedColourOptions.length > 0 ?
                //   selectedColourOptions.map((element) =>

                //     <>

                //       {element.company_name ? element.company_name + " , " : "-"}
                //     </>)
                //   : null}
                />

              </Grid>



              <div style={{ margin: "7px" }}></div>

              <Grid item xs={12}></Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              className={classes.submit}
            >
              Save
            </Button>
          </ValidatorForm>

        </div>
      </Container>
    </Dialog>
  );
}
