import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  CssBaseline,
  Grid,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  ValidatorForm,
  TextValidator,
} from "react-material-ui-form-validator";
import { Button } from "../../../../components/Wrappers/Wrappers";
import Select2 from 'react-select';

export default function AddInternalStaffDialog(props) {
  let {
    classes,
    formData,
    staffCreateModal,
    handleClose,
    handleChangeInput,
    handleChangeInputLandlords,
    handleSubmit,
    landlords,
  } = props;
  console.log("MODEL____landlords:::", landlords);
  let landlordsListitemsTemp = landlords && landlords.length ? landlords.map((element) => ({ value: element.id, label: element.company_name })) : []
  return (
    <Dialog fullScreen open={staffCreateModal} onClose={handleClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Add New Internal Staff
          </Typography>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Add Internal Staff
          </Typography>

          <ValidatorForm onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="First Name*"
                  fullWidth
                  onChange={handleChangeInput}
                  name="first_name"
                  id="first_name"
                  value={formData.first_name}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Last Name*"
                  id="last_name"
                  fullWidth
                  onChange={handleChangeInput}
                  name="last_name"
                  value={formData.last_name}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Email*"
                  id="email"
                  fullWidth
                  onChange={handleChangeInput}
                  name="email"
                  value={formData.email}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Password*"
                  id="password"
                  fullWidth
                  onChange={handleChangeInput}
                  name="password"
                  type="password"
                  value={formData.password}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  label="Contact No.*"
                  id="contact"
                  fullWidth
                  onChange={handleChangeInput}
                  name="contact"
                  value={formData.contact}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  label="Address*"
                  id="address"
                  fullWidth
                  onChange={handleChangeInput}
                  name="address"
                  value={formData.address}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12}>
                <FormControl
                  className={"classes.formControl"}
                  style={{ width: "100%" }}
                >
                  <SelectValidator
                    fullWidth
                    label="Landlord"
                    labelId="select-contractor-label"
                    id="select-contractor"
                    name="parentId"
                    value={formData.parentId}
                    onChange={handleChangeInput}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                  >
                    <MenuItem value={""}>Please Select</MenuItem>
                    {landlords &&
                      landlords.length > 0 &&
                      landlords.map((landlord) => {
                        return (
                          <MenuItem key={landlord.id} value={landlord.id}>
                            {landlord.company_name
                              ? landlord.company_name
                              : "n/a"}
                          </MenuItem>
                        );
                      })}
                  </SelectValidator>
                </FormControl>
              </Grid> */}

              <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                <label htmlFor="resourceList" style={{ color: 'grey' }}>Landlord</label>
                <Select2
                  width="full"
                  name="parentId"
                  id="parentId"
                  onChange={handleChangeInputLandlords}
                  value={landlordsListitemsTemp.filter(function (option) {
                    return option.value === formData.parentId;
                  })}
                  options={landlordsListitemsTemp}
                  className="basic-multi-select width: 'full'"
                  classNamePrefix="Activities"
                />
              </Grid>

              <Grid item xs={12}></Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              className={classes.submit}
            >
              Save
            </Button>
          </ValidatorForm>
        </div>
      </Container>
    </Dialog>
  );
}
