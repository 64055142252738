import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@material-ui/core";
import { Button } from "../../../../components/Wrappers/Wrappers";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";



const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
});

function TableComponent(props) {
  let { data, offset,
    updatehandleOpenCreateModal,
    // updatePwdhandleOpenCreateModal,
    handleSort,
    sortOrder,
    sortBy,
    classes,
    onApprove,
    onDeleteClick,
    onDisable
  } = props;
  // var keys = Object.keys(data[0]).map(i => i.toUpperCase());
  // keys.shift(); // delete "id" key
  console.log('######################################', data);
  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <Table className="mb-0">
          <TableHead>
            <TableRow>
              <TableCell >S. NO.</TableCell>
              <TableCell className="pl-3 fw-normal">Package </TableCell>
              <TableCell className="pl-3 fw-normal">Minimum </TableCell>
              <TableCell className="pl-3 fw-normal">Maximum </TableCell>
              <TableCell className="pl-3 fw-normal">Company Name</TableCell>
              <TableCell className="pl-3 fw-normal">Contractor</TableCell>

              {/* <TableCell style={{ cursor: 'pointer' }} onClick={() => handleSort('email')} >Email
                {sortBy === 'email' && sortOrder === '-1' ?
                  <ArrowUpwardIcon /> : sortBy === 'email' && sortOrder === '1' ? <ArrowDownwardIcon /> : null}</TableCell> */}




              {/* <TableCell >DOJ</TableCell> */}
              {/* <TableCell >RATING</TableCell> */}
              {/* <TableCell >MOBILE</TableCell> */}
              <TableCell style={{ textAlign: 'center' }}>ACTION</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {

              data.map((tempdata, index) => (

                <TableRow key={index}>
                  <TableCell className="pl-3 fw-normal">{offset + index + 1}</TableCell>
                  <TableCell className="pl-3 fw-normal">{tempdata && tempdata.packageId ? tempdata.packageId.packName : "-"}</TableCell>
                  <TableCell className="pl-3 fw-normal">{tempdata && tempdata.packageId ? tempdata.packageId.min : 0}</TableCell>
                  <TableCell className="pl-3 fw-normal">{tempdata && tempdata.packageId ? tempdata.packageId.max : 0}</TableCell>
                  <TableCell className="pl-3 fw-normal">{tempdata && tempdata.userId ? tempdata.userId.company_name : "-"}</TableCell>
                  <TableCell className="pl-3 fw-normal">{tempdata && tempdata.userId ? tempdata.userId.contact_name : "-"}</TableCell>
                  {/* <TableCell>{tempdata.email}</TableCell> */}
                  <TableCell style={{ textAlign: 'center' }}>
                    {/* <Button
                      color="success"
                      size="small"
                      className="px-2"
                      variant="contained"
                      style={{ marginRight: '5px' }}
                      onClick={() => updatehandleOpenCreateModal(tempdata)}
                    >
                      update
                    </Button> */}

                    {/* <Button
                      color="success"
                      size="small"
                      className="px-2"
                      variant="contained"
                      style={{ marginRight: '5px' }}
                      onClick={() => updatePwdhandleOpenCreateModal(tempdata)}
                    >
                      updatePassword
                    </Button> */}

                    {tempdata.isApprove == false ? <> <Button
                      color={tempdata.isApprove ? "success" : "warning"}
                      size="small"
                      className="px-2"
                      variant="contained"
                      style={{ marginRight: '5px' }}
                      onClick={() => onApprove(tempdata)}
                    >
                      {tempdata.isApprove ? 'Approved' : 'Approve'}
                    </Button></> : <><Button
                      color={tempdata.isApprove ? "success" : "warning"}
                      size="small"
                      className="px-2"
                      variant="contained"
                      style={{ marginRight: '5px' }}
                    // onClick={() => onApprove(tempdata)}
                    >
                      {tempdata.isApprove ? 'Approved' : 'Approve'}
                    </Button></>}




                    {/* <Button
                      color={tempdata.isDisable ? "warning" : "primary"}
                      size="small"
                      className="px-2"
                      variant="contained"
                      style={{ marginRight: '5px' }}
                      onClick={() => onDisable(tempdata)}
                    >
                      {tempdata.isDisable ? 'Enable' : 'Disable'}
                    </Button>
                    <Button
                      color="error"
                      size="small"
                      className="px-2"
                      variant="contained"
                      style={{ marginRight: '5px' }}
                      onClick={() => onDeleteClick(tempdata)}
                    >
                      Delete
                    </Button> */}
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </Table>
    </Paper>
  );
}

export default withStyles(styles)(TableComponent);
