import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from "../../../../components/Wrappers/Wrappers";
import ProfileLandlord from "../ProfileLandlord/ProfileLandlord";
import LocationTable from "../LocationTable/LocationTable";
import LandlordMoreDetails from "../LandlordMoreDetails/LandlordMoreDetails";
import { landlordActions } from "../../../../_actions";
import axios from "axios";
import { CONST } from "../../../../_config";
import { authHeader } from "../../../../_helpers";

const ViewLandlordDialog = (props) => {
  let {
    classes,
    landlordCreateModal,
    handleClose,
    viewRowData,
    landlord,
    handleOpenLocationCreateModal,
  } = props;

  const [selectedFieldState, setSelectedFieldState] = useState({
    selectedField: "personal_info",
  });

  const [offset, setOffset] = useState("");
  const [keyWord, setKeyWord] = useState("");
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(false);

  const [siteInfoData, setSiteInfoData] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    const data = {
      id: viewRowData.id,
    };
    if (viewRowData.id) {
      dispatch(landlordActions.getLandlordDetails(data));
    }
    setSelectedFieldState({
      selectedField: "personal_info",
    });
    setLocation("");
    setSiteInfoData("");
  }, [viewRowData.id]);

  const onChangeHandler = (event) => {
    const { value } = event.target;
    switch (value) {
      case "pesonal_info":
        setLocation("");
        setSiteInfoData("");
        break;
      case "locations_info":
        break;
      default:
        break;
    }
    setSelectedFieldState(() => {
      return { selectedField: value };
    });
  };

  const locationHandler = (location, reload) => {
    if (reload) {
      setLoading(true);
    }
    setLocation(location);
    axios
      .post(
        CONST.BACKEND_URL + "/getSiteInfo",
        { locationId: location.id },
        {
          headers: {
            Authorization: authHeader().Authorization,
          },
        },
      )
      .then((res) => {
        setLoading(false);
        if (res.data.message) {
          setSiteInfoData(res.data.message);
        }
      })
      .catch((err) => {
        console.log("errrrrrr", err);
        setLoading(false);
      });
  };
  const handleSearch = (event) => {
    console.log('*-***************************111111111111111111');
    event.preventDefault();
    let { value } = event.target;
    // this.setState({ keyWord: value, offset: 0 });
    setKeyWord(value);
    setOffset(0)
    // const data = {
    //   id: viewRowData.id,
    // };
    let data = {
      id: viewRowData.id,
      keyWord: value,
      // pageNo: 1,
      // roll_id: 0,
      // size: this.state.size,
    };
    dispatch(landlordActions.getLandlordDetails(data));
  }

  return (
    <Dialog fullScreen open={landlordCreateModal} onClose={handleClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Landlord Details
          </Typography>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: "flex",
            justifyContent: "space-around",
            padding: "0 30px",
          }}
        >
          <Grid container>
            <Grid item xs={6} md={6}>
              <Grid item>
                <FormControl component="fieldset" style={{ marginTop: "27px" }}>
                  <RadioGroup
                    row
                    aria-label="selectedField"
                    name="selectedField"
                    value={selectedFieldState.selectedField}
                    onChange={(e) => onChangeHandler(e)}
                  >
                    <FormControlLabel
                      value="personal_info"
                      control={<Radio color="primary" />}
                      label="Personal Info"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="locations_info"
                      control={<Radio color="primary" />}
                      label="Locations"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {selectedFieldState.selectedField === "personal_info" && (
                <ProfileLandlord landlord={landlord} classes={classes} />
              )}
              {selectedFieldState.selectedField === "locations_info" && (
                <LocationTable
                  landlord={landlord}
                  keyWord={keyWord}
                  handleOpenLocationCreateModal={handleOpenLocationCreateModal}
                  locationHandler={locationHandler}
                  locationID={location.id}
                  handleSearch={handleSearch}
                />
              )}
            </Grid>
            {selectedFieldState.selectedField === "locations_info" && (
              <Grid item xs={6} md={6}>
                {siteInfoData && !loading ? (
                  <LandlordMoreDetails
                    classes={classes}
                    location={location}
                    landlord={landlord}
                    siteInfoData={siteInfoData}
                    locationHandler={locationHandler}

                  />
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h3>
                        {loading ? "Loading..." : "Please select a location"}
                      </h3>
                    </div>
                  </>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid></Grid>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  const { landlords } = state;
  return {
    landlord: landlords.landlordDetails,
  };
};
const mapDispatchToProps = () => ({
  // dispatch(landlordActions.getLandlordDetails)
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewLandlordDialog);
