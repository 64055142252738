import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@material-ui/core";
import { Button } from "../../../../components/Wrappers/Wrappers";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

// const states = {
//   true: "success",
//   false: "warning",
//   declined: "secondary",
// };

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
});

function TableComponent(props) {
  let { data, offset,
    updatehandleOpenCreateModal,
    // updatePwdhandleOpenCreateModal,
    handleSort,
    sortOrder,
    sortBy,
    classes,

    onDeleteClick,
    onDisable
  } = props;
  // var keys = Object.keys(data[0]).map(i => i.toUpperCase());
  // keys.shift(); // delete "id" key

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <Table className="mb-0">
          <TableHead>
            <TableRow>
              <TableCell >S. NO.</TableCell>
              <TableCell className="pl-3 fw-normal">Package Name</TableCell>
              <TableCell className="pl-3 fw-normal">Description</TableCell>
              <TableCell className="pl-3 fw-normal">Minimum</TableCell>
              <TableCell className="pl-3 fw-normal">Maximum</TableCell>
              <TableCell className="pl-3 fw-normal">Slug</TableCell>

              {/* <TableCell style={{ cursor: 'pointer' }} onClick={() => handleSort('email')} >Email
                {sortBy === 'email' && sortOrder === '-1' ?
                  <ArrowUpwardIcon /> : sortBy === 'email' && sortOrder === '1' ? <ArrowDownwardIcon /> : null}</TableCell> */}




              {/* <TableCell >DOJ</TableCell> */}
              {/* <TableCell >RATING</TableCell> */}
              {/* <TableCell >MOBILE</TableCell> */}
              <TableCell style={{ textAlign: 'center' }}>ACTION</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              data.map((tempdata, index) => (
                <TableRow key={index}>
                  <TableCell className="pl-3 fw-normal">{offset + index + 1}</TableCell>
                  <TableCell className="pl-3 fw-normal">{tempdata.packName}</TableCell>
                  <TableCell className="pl-3 fw-normal">{tempdata.desc}</TableCell>
                  <TableCell className="pl-3 fw-normal">{tempdata.min}</TableCell>
                  <TableCell className="pl-3 fw-normal">{tempdata.max}</TableCell>
                  <TableCell className="pl-3 fw-normal">{tempdata.slug}</TableCell>
                  {/* <TableCell>{tempdata.email}</TableCell> */}
                  <TableCell style={{ textAlign: 'center' }}>
                    <Button
                      color="success"
                      size="small"
                      className="px-2"
                      variant="contained"
                      style={{ marginRight: '5px' }}
                      onClick={() => updatehandleOpenCreateModal(tempdata)}
                    >
                      update
                    </Button>

                    {/* <Button
                      color="success"
                      size="small"
                      className="px-2"
                      variant="contained"
                      style={{ marginRight: '5px' }}
                      onClick={() => updatePwdhandleOpenCreateModal(tempdata)}
                    >
                      updatePassword
                    </Button> */}

                    <Button
                      color={tempdata.isDisable ? "warning" : "primary"}
                      size="small"
                      className="px-2"
                      variant="contained"
                      style={{ marginRight: '5px' }}
                      onClick={() => onDisable(tempdata)}
                    >
                      {tempdata.isDisable ? 'Enable' : 'Disable'}
                    </Button>
                    <Button
                      color="secondary"
                      size="small"
                      className="px-2"
                      variant="contained"
                      style={{ marginRight: '5px' }}
                      onClick={() => onDeleteClick(tempdata)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </Table>
    </Paper>
  );
}

export default withStyles(styles)(TableComponent);
